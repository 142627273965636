export default function deepEqual(obj1: any, obj2: any): boolean {
  // Check if both are exactly the same object
  if (obj1 === obj2) return true

  // Check if either is null or not an object
  if (
    obj1 == null ||
    obj2 == null ||
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object'
  ) {
    return false
  }

  // Get object keys
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  // Check if they have the same number of keys
  if (keys1.length !== keys2.length) return false

  // Check if all keys in obj1 are in obj2 with the same values
  return keys1.every((key) => {
    return keys2.includes(key) && deepEqual(obj1[key], obj2[key])
  })
}
